/* General */

:root {
  --blueish: #72c4d0;
  --seafoam: #a5d6c3;
  --grey: #f2f2f2;

  --mobile-nav-height: 60px;
}

body {
  margin: 0;
  padding: 0;
}

button {
  display: block;
  border: none;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}

ul,
li {
  padding-left: 10px;
}

li {
  list-style-type: none;
}

/* ID*/

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 75%;
  left: 25%;
}

#listing .listing-item:nth-child(odd) {
  background-color: var(--grey);
}

#listing p {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
}

#listing .date {
  z-index: 2;
  float: right;
}

#listing {
  padding: 5px;
  overflow: auto;
}

#loading-overlay {
  top: 0px;
  position: fixed;
  width: 25%;
  height: 100vh;
  background-color: var(--seafoam);
  opacity: 1;
  transition: opacity 0.75s;
  z-index: 3;
}

#mobile-nav {
  display: none;
}

#loading-overlay.fade {
  opacity: 0;
}

#loading {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(12.5% - (80px / 2));
  z-index: 2;
  filter: invert(100%);
}

#map-overlay {
  position: absolute;
  width: 25%;
  top: 0;
  bottom: 0;
  left: 0;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #fff;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  font-size: 14px;
}

#filter-top {
  display: flex;
}

#filters {
  display: flex;
}

#filter button {
  width: 100%;
}

#filter-button {
  width: calc(100% - 2 * 10px);
}

#date-selectors {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  font-family: monospace;
}

#dates-selectors p {
  margin: 0px;
}
#date-selectors label {
  font-size: 16px;
  border-radius: 2px;
  padding: 10px;
  outline-width: 1px;
  outline-style: outset;
  background-color: var(--grey);
}

#date-selectors input {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

#about-icon {
  position: fixed;
  top: 10px;
  right: 65px;
  width: 45px;
  background-color: white;
  z-index: 3;
  height: 45px;
  border-radius: 3px;
}

#about-icon:hover {
  cursor: pointer;
}

#about-icon.invert {
  left: unset;
  right: 10px;
  filter: invert(100%);
}

.about-icon-item {
  position: relative;
  top: 25%;
}

#about-icon span {
  position: relative;
  top: 45%;
  display: flex;
  justify-content: center;
  font-size: 1.25em;
  font-weight: bold;
}

#about-overlay {
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #fff;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  font-size: 14px;
  z-index: 2;
}

#about-content {
  display: block;
  margin-left: 25px;
  margin-right: 25px;
  max-width: 50em;
  overflow-y: auto;
  font-size: 16px;
  line-height: 1.5;
}

#about-content h2 {
  margin-top: 10px;
  padding-top: 10px;
}

/* Class */

.neutral-button {
  color: #111;
  background-color: var(--seafoam);
}

.positive-button {
  color: #111;
  background-color: var(--blueish);
}

.negative-button {
  color: #eee;
  background-color: black;
}

.hidden {
  display: none !important;
}

.bold {
  font-weight: bold;
}

.maplibregl-popup {
  min-width: 400px;
  max-width: 600px;
  max-height: 400px;
}

.maplibregl-popup .popup-listing {
  margin-top: 10px;
  overflow-y: auto;

  max-height: 400px;
}
.maplibregl-popup-content {
  padding: 0px;
  padding-bottom: 15px;
}

.maplibregl-popup-content .listing-item p {
  padding-left: 10px;
}

.maplibregl-popup-content .date {
  font-weight: bold;
}

.maplibregl-popup-content .venue {
  font-size: 18px;
  margin-top: 0px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--seafoam);
}

.listing-item {
  background-color: #fff;
  border-radius: 5px;
  font-size: 18px;
  padding: 5px;
}

.listing-item ul {
  margin: 2px;
}

.listing-item li {
  margin-top: 5px;
  margin-bottom: 5px;
}

#listing .listing-item:hover {
  filter: invert(100%);
}

.listing-item .date {
  margin-right: 10px;
}

.tip {
  background-color: var(--seafoam);
  border-left: 6px solid #04aa6d;
  padding-left: 10px;
  padding-right: 10px;
}

/* Dev */
#geocoder {
  position: relative;
  float: left;
  left: 25%;
}

#result {
  z-index: 2;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 25%;
  background: #ddd;
  height: 100%;
  opacity: 0.5;
  text-wrap: auto;
}

#result:hover {
  opacity: 1;
}

.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}

/* scroll bars */

/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid var(--dark);
}

@media only screen and (max-device-width: 580px) {
  #map {
    width: 100%;
    left: 0px;
  }

  #map-overlay {
    position: absolute;
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }

  #about-overlay {
    position: absolute;
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }

  #date-selector-container {
    grid-template-columns: unset;
  }

  .map-overlay .closebtn {
    position: absolute;
    display: block;
    left: 0px;
    bottom: 25px;
    width: 100%;
    text-align: center;
    font-size: 64px;
  }

  #mobile-nav {
    display: block;
    background: rgba(0, 0, 0, 0);
    bottom: 0px;
    height: var(--mobile-nav-height);
    left: 0px;
    position: absolute;
    width: 50%;
    left: 25%;
    z-index: 0;
  }

  #mobile-nav button {
    opacity: 1;
    width: 100%;
    height: 40px;
  }

  #listing {
    margin-bottom: var(--mobile-nav-height);
  }

  #loading-overlay {
    width: 100vw;
  }

  #loading {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 40%;
  }

  .maplibregl-popup {
    min-width: 300px;
    max-width: 450px;
  }

  #about-icon {
    top: 10px;
    left: 10px;
    right: unset;
  }
}

/** Vanity */

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
